import React from "react";
import { RichTextEditorUtils } from "@my-scoot/component-library-legacy";
import LaptopIcon from "@material-ui/icons/Laptop";
import PhoneIcon from "@material-ui/icons/StayCurrentPortrait";

const { emptyHtmlWithListStyle } = RichTextEditorUtils;

export const PREVIEW_BLOG_TABS = {
  DESKTOP_VIEW: 1,
  MOBILE_VIEW: 2,
};

export const BLOG_TABS = {
  PUBLISHED: { label: "Published blog", value: 1, status: "published" },
  DRAFT: { label: "Drafts", value: 2, status: "draft" },
  SCHEDULED: { label: "Scheduled", value: 3, status: "scheduled" },
};

export const filterConfig = [
  {
    source: "title",
    placeholder: "Title",
    type: "input",
    disableContains: true,
  },
];

export const filterChipFormatter = (filter, value) => {
  switch (filter) {
    case "title":
      return `Title: ${value}`;
    default:
      return null;
  }
};

export const blogFormInitialValues = {
  id: "",
  title: null,
  content: emptyHtmlWithListStyle,
  thumbnail_image: null,
  scheduled_at: new Date(),
  is_scheduled: false,
  recipients: [],
  is_draft: true,
};

export const TABS = [
  { label: <LaptopIcon />, value: PREVIEW_BLOG_TABS.DESKTOP_VIEW },
  { label: <PhoneIcon />, value: PREVIEW_BLOG_TABS.MOBILE_VIEW },
];

export const BLOGS_LEARN_MORE_HREFS = {
  BLOGS:
    "https://support.exlyapp.com/support/solutions/articles/84000347729-write-a-blog-post-with-exly",
  BLOGS_VIDEO: "https://www.youtube-nocookie.com/embed/VSUFK_uLaRk",
};
