import { useCallback, useMemo, useState } from "react";

import qs from "query-string";
import { useHistory, useLocation } from "react-router";

import useHideNavAndAppBar from "hooks/useHideNavAndAppBar";

import FacebookIcon from "assets/vectors/social/facebook.svg";
import WhatsAppIcon from "assets/vectors/social/whatsapp.svg";

import { useListController, useRefresh } from "react-admin";
import { useNotifications } from "utils/hooks";

import { is_empty } from "utils/validations";

import { api, dataProvider } from "data";
import moment from "moment";
import { getCreatorHostsiteURL } from "features/Account/utils/getCreatorPageDetails";
import { getAuthData } from "utils/AuthUtil";
import { getParamValueFromRouteProps } from "utils/Utils";
import {
  blogFormInitialValues,
  BLOG_TABS,
  BLOGS_LEARN_MORE_HREFS,
} from "./data";
import constants from "constants/constants";
import { RichTextEditorUtils } from "@my-scoot/component-library-legacy";
import { orgPermissions, RESOURCE_KEYS } from "utils/OrgPermissions";
import { appendUrlParams } from "utils/urlUtils";
import { generateRAurl } from "utils/urlUtils";
import { DEFAULT_TIME_FORMAT_12 } from "constants/dateTime";
import useAppLayoutControl from "hooks/useAppLayoutControl";

const { getHtmlFromEditorState } = RichTextEditorUtils;

export const useBlogs = (props) => {
  const {
    loaded,
    loading,
    data,
    filterValues,
    setFilters,
    page,
    setPage,
    total,
  } = useListController({
    ...props,
    perPage: 50,
    filterDefaultValues: {
      status: BLOG_TABS.PUBLISHED.status,
    },
  });

  const refetch = useRefresh();
  const { notify } = useNotifications();
  const hasWriteAccess = orgPermissions.hasFeatureWriteAccess(
    RESOURCE_KEYS.SECTIONS.BLOGS
  );

  const { title } = filterValues;
  const showEmptyState = is_empty(data) && !loading && is_empty(title);
  const showNoResultFound = is_empty(data) && !loading && !is_empty(title);

  const defaultSelectedTab =
    Object.values(BLOG_TABS).find((tab) => tab.status === filterValues.status)
      ?.value || BLOG_TABS.PUBLISHED.value;

  const auth = getAuthData();

  const display_name = auth?.display_name;

  const blogs = useMemo(() => {
    return Object.values(data).reverse();
  }, [data]);

  const [selectedRecordData, setSelectedRecordData] = useState(null);
  const [selectedBlogForComments, setSelectedBlogForComments] = useState(null);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showShareModal, setShowShare] = useState(false);
  const [showMoreModal, setShowMoreModal] = useState(false);
  const [showCreateBlogModal, setShowCreateBlogModal] = useState(false);

  const [editMode, setEditMode] = useState(false);

  const [currentTab, setCurrentTab] = useState(defaultSelectedTab);
  const [isTabChanged, setIsTabChanged] = useState(false);
  const [formInitialValues, setFormInitialValues] = useState(
    blogFormInitialValues
  );
  const [submitting, setSubmitting] = useState(false);

  const history = useHistory();
  const location = useLocation();
  const showCommentsModal = getParamValueFromRouteProps(
    { location },
    "showCommentsModal"
  );
  const selectedBlogUuid = getParamValueFromRouteProps(
    { location },
    "blog_uuid"
  );

  const { is_draft, is_scheduled } = selectedRecordData || {};
  const isScheduled = is_draft && is_scheduled;
  const isDraft = is_draft && !is_scheduled;
  const isPublished = !isScheduled && !isDraft;

  const handleOpenModal = () => {
    setShowMoreModal(true);
  };

  const handleCloseModal = () => {
    setShowMoreModal(false);
  };

  const handleDelete = useCallback(async () => {
    try {
      if (hasWriteAccess) {
        const status = await dataProvider.custom_request(
          `${api.handle_blog}${selectedRecordData.id}/`,
          "DELETE"
        );
        notify(status?.message, "success");
        setSelectedRecordData(null);
        refetch();
        setShowDeleteModal(false);
      }
    } catch (err) {
      notify(
        err?.body?.message ? err?.body?.message : constants.error_message,
        "error"
      );
      setSelectedRecordData(null);
      setShowDeleteModal(false);
    }
  }, [selectedRecordData]);

  const handleEdit = async ({ id: blog_id }) => {
    try {
      if (hasWriteAccess && !is_empty(blog_id)) {
        let data = await dataProvider.custom_request(
          `${api.handle_blog}${blog_id}/`
        );
        if (data.status === 200 && !is_empty(data.data)) {
          setFormInitialValues((prev) => {
            const newValues = { ...prev, ...data.data };

            let { scheduled_at } = data.data;
            const isScheduled = !is_empty(scheduled_at);

            if (isScheduled) {
              newValues.scheduled_at = new Date(
                moment(scheduled_at).toString()
              );

              newValues.scheduled_time = moment(scheduled_at)
                .format(DEFAULT_TIME_FORMAT_12)
                .toString();
            } else newValues.scheduled_at = new Date();

            return newValues;
          });

          setShowCreateBlogModal(true);
          setEditMode(true);
        }
      }
    } catch (err) {
      notify(
        err?.body?.message ? err?.body?.message : constants.error_message,
        err
      );
    }
  };

  const handleCreate = () => {
    setShowCreateBlogModal(true);
  };

  const onOpenCommentsModal = (event, blogData) => {
    event.stopPropagation();
    const query = { showCommentsModal: true, blog_uuid: blogData.uuid };
    const searchString = qs.stringify(query);
    history.push({ search: searchString });
  };

  const onCloseCommentsModal = useCallback(() => {
    history.push({ search: "" });
    refetch();
  }, [refetch]);

  const handleTabChange = useCallback((tab) => {
    switch (tab) {
      case BLOG_TABS.DRAFT.value:
        history.replace(
          generateRAurl(
            location.pathname,
            { status: BLOG_TABS.DRAFT.status },
            "ASC",
            page,
            50
          )
        );
        break;
      case BLOG_TABS.SCHEDULED.value:
        history.replace(
          generateRAurl(
            location.pathname,
            { status: BLOG_TABS.SCHEDULED.status },
            "ASC",
            page,
            50
          )
        );
        break;
      default:
        history.replace(
          generateRAurl(
            location.pathname,
            { status: BLOG_TABS.PUBLISHED.status },
            "ASC",
            page,
            50
          )
        );
    }

    setCurrentTab(tab);
    if (!isTabChanged) setIsTabChanged(true);
  }, []);

  const handleShareBlog = () => {
    handleCloseModal();
    setShowShare(true);
  };

  const getEmptyStateProps = useCallback(
    (blogType) => {
      let emptyState = {
        title: "Write a blog post",
        description:
          "As soon as we have a new blog post, it will show up here. View the video to learn more.",
        primaryCtaProps: hasWriteAccess && {
          title: "Create a Blog Post",
          onClick: handleCreate,
        },
      };

      if (blogType === BLOG_TABS.PUBLISHED.value) {
        emptyState.videoSrc = BLOGS_LEARN_MORE_HREFS.BLOGS_VIDEO;
      }

      if (blogType === BLOG_TABS.DRAFT.value)
        return {
          ...emptyState,
          imgSrc:
            "/assets/vectors/sales_and_marketing/broadcast/draft_illustration.svg",
          title: "You have no drafts saved currently. Start drafting one.",
        };
      else if (blogType === BLOG_TABS.SCHEDULED.value)
        return {
          ...emptyState,
          imgSrc:
            "/assets/vectors/sales_and_marketing/broadcast/scheduled_illustration.svg",
          title: "You have no blogs scheduled. Schedule one now.",
        };

      return emptyState;
    },
    [hasWriteAccess]
  );

  const handleUpdate = async (blog_data, preview) => {
    const {
      title,
      content,
      thumbnail_image,
      scheduled_at,
      scheduled_time,
      is_scheduled,
      recipients,
      id: blog_id,
      is_draft,
    } = blog_data;

    try {
      setSubmitting(true);

      let payload = {
        title,
        content: getHtmlFromEditorState(content),
        thumbnail_image,
      };
      if (is_draft === true) {
        const time = moment(scheduled_time, DEFAULT_TIME_FORMAT_12);
        const hours = time.hour();
        const minutes = time.minute();

        const is_scheduled_at = scheduled_at ? scheduled_at : new Date();

        const scheduledAt = moment(is_scheduled_at.toString())
          .set({ h: hours, m: minutes })
          .utc()
          .toISOString();

        Object.assign(payload, {
          scheduled_at: scheduledAt,
          is_scheduled: is_scheduled,
          is_draft: is_scheduled,
          recipients: recipients,
        });
      }

      if (preview) {
        payload = { ...payload, is_draft: true };
      }

      const status = await dataProvider.custom_request(
        `${api.handle_blog}${blog_id}/`,
        "PATCH",
        payload
      );
      if (status.status === 200) {
        notify(status.message, "success");
        window.location.href = `${window.location.origin}/#/Blogs`;

        if (preview && !is_empty(status?.data?.uuid)) {
          openPreview(status?.data?.uuid);
        }
      }
    } catch (err) {
      notify(
        !is_empty(err?.body?.message)
          ? err?.body?.message
          : constants.error_message,
        "error"
      );
    } finally {
      if (!preview) {
        setShowCreateBlogModal(false);
        setFormInitialValues(blogFormInitialValues);
      }
      setSubmitting(false);
      refetch();
    }
  };

  const handleSubmit = async (
    blog_data,
    draft_status = true,
    preview = false
  ) => {
    const {
      title,
      content,
      thumbnail_image,
      scheduled_at,
      scheduled_time,
      is_scheduled,
      recipients,
    } = blog_data;

    try {
      setSubmitting(true);

      let payload = {
        title,
        content: getHtmlFromEditorState(content),
        thumbnail_image,
        is_draft: is_scheduled ? true : draft_status,
        recipients: recipients,
      };
      if (is_scheduled) {
        const time = moment(scheduled_time, DEFAULT_TIME_FORMAT_12);
        const hours = time.hour();
        const minutes = time.minute();

        const scheduledAt = moment(scheduled_at.toString())
          .set({ h: hours, m: minutes })
          .utc()
          .toISOString();

        Object.assign(payload, {
          scheduled_at: scheduledAt,
          is_scheduled: is_scheduled,
        });
      }

      const status = await dataProvider.custom_request(
        api.handle_blog,
        "POST",
        payload
      );
      if (status.status === 200) {
        notify(status.message, "success");

        if (preview && !is_empty(status?.data?.uuid)) {
          window.open(
            `${window.location.origin}/#/PreviewBlog/${status?.data?.uuid}`
          );
          setFormInitialValues((prev) => ({ ...prev, ...status.data }));
        } else {
          setShowCreateBlogModal(false);
        }
      }
    } catch (err) {
      notify(
        !is_empty(err?.body?.message)
          ? err?.body?.message
          : constants.error_message,
        "error"
      );
      setShowCreateBlogModal(false);
      setFormInitialValues(blogFormInitialValues);
    } finally {
      setSubmitting(false);
      refetch();
    }
  };

  const openPreview = (blog_uuid) => {
    window.open(`${window.location.origin}/#/PreviewBlog/${blog_uuid}`);
  };

  const getShareModalProps = () => {
    const props = {
      title: "Share your blog",
      platformContainerText: "Share blog post on Social Media",
      copyLinkBtnText: "Copy blog link",
      open: showShareModal,
      onClose: () => setShowShare(false),
      showCopyLink: !is_empty(auth) && auth.sub_domain,
      link: selectedRecordData
        ? `${getCreatorHostsiteURL()?.url}/blog_post/${selectedRecordData.uuid}`
        : "",
      platforms: [],
    };

    if (!is_empty(auth) && auth.sub_domain) {
      props.platforms.push({
        icon: WhatsAppIcon,
        onClick: () => {
          const share = `https://api.whatsapp.com/send?&text=${
            selectedRecordData ? `Have a look at my blog, ${props.link}` : ""
          }`;
          window.open(share);
          setShowShare(false);
        },
      });
    }

    props.platforms.push({
      icon: FacebookIcon,
      onClick: () => {
        const share = appendUrlParams(constants.facebook_share_url, {
          link: props.link,
          quote: props.title,
        });
        window.open(share);
        setShowShare(false);
      },
    });

    return props;
  };

  useHideNavAndAppBar("Create/edit blog post");

  useAppLayoutControl({
    values: {
      learnMoreHref: BLOGS_LEARN_MORE_HREFS.BLOGS,
    },
  });

  return {
    BLOG_TABS,
    blogFormInitialValues,
    loaded,
    loading,
    data,
    filterValues,
    setFilters,
    page,
    setPage,
    total,
    defaultSelectedTab,
    blogs,
    display_name,
    submitting,
    isDraft,
    isScheduled,
    isPublished,

    showEmptyState,
    showNoResultFound,

    selectedRecordData,
    setSelectedRecordData,

    selectedBlogForComments,
    setSelectedBlogForComments,

    showDeleteModal,
    setShowDeleteModal,

    showShareModal,
    setShowShare,

    showMoreModal,
    setShowMoreModal,

    showCreateBlogModal,
    setShowCreateBlogModal,

    editMode,
    setEditMode,

    currentTab,
    isTabChanged,
    setCurrentTab,

    formInitialValues,
    setFormInitialValues,

    showCommentsModal,
    selectedBlogUuid,

    handleOpenModal,
    handleCloseModal,
    handleDelete,
    handleEdit,
    handleCreate,
    onOpenCommentsModal,
    onCloseCommentsModal,
    handleTabChange,
    handleShareBlog,
    getEmptyStateProps,
    handleUpdate,
    handleSubmit,
    openPreview,

    getShareModalProps,
    hasWriteAccess,
  };
};
