import { isBefore } from "date-fns";
import { CREATE_MODAL_FORM_KEYS } from "../constants/CreateBlogModal.constants";

const parseTime = (timeStr: string): { hours: number; minutes: number } => {
  const [time, modifier] = timeStr.split(" ");
  let [hours, minutes] = time.split(":").map(Number);

  if (modifier.toLowerCase() === "pm" && hours !== 12) {
    hours += 12;
  }
  if (modifier.toLowerCase() === "am" && hours === 12) {
    hours = 0;
  }

  return { hours, minutes };
};

const combineDateAndTime = (date: Date, timeStr: string): Date => {
  const { hours, minutes } = parseTime(timeStr);
  const combinedDate = new Date(date);
  combinedDate.setHours(hours);
  combinedDate.setMinutes(minutes);
  combinedDate.setSeconds(0);
  combinedDate.setMilliseconds(0);
  return combinedDate;
};

const isDateInThePast = (date: Date, timeStr: string): boolean => {
  const combinedDate = combineDateAndTime(date, timeStr);
  const now = new Date();
  return isBefore(combinedDate, now);
};

export const validateScheduleTime = (
  value: string,
  formData: { [key: string]: string | number | Date }
) => {
  const {
    [CREATE_MODAL_FORM_KEYS.scheduled_at]: scheduledAt,
    [CREATE_MODAL_FORM_KEYS.is_scheduled]: isScheduled,
  } = formData;

  if (!value || !scheduledAt || !isScheduled) return;

  return (
    isDateInThePast(scheduledAt as Date, value) && "Please pick time in future"
  );
};
