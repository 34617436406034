import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      background: theme?.palette?.primary?.shade_50,
      borderRadius: "10px",
      overlay: "hidden",
      display: "flex",
      alignItems: "center",
      position: "relative",
      flexDirection: ({ isDesktop }) => (isDesktop ? "row" : "column"),
      padding: ({ isDesktop }) => (isDesktop ? "0 16px 0 0" : "16px"),
      textAlign: ({ isDesktop }) => !isDesktop && "center",
      backgroundSize: "cover",
    },
    imgIcon: {
      fontSize: ({ isDesktop }) => (isDesktop ? "120px" : "52px"),
      color: theme?.palette?.secondary?.shade_600,
    },
    title: {
      fontSize: "15px",
      lineHeight: "15px",
    },
    contentContainer: {
      color: theme?.palette?.basic?.black,
      fontSize: "12px",
      lineHeight: "14px",
      display: "flex",
      flexDirection: "column",
      gap: ({ isDesktop }) => (isDesktop ? "8px" : "12px"),
    },
    overlay: {
      opacity: ({ hover }) => (hover ? 0.7 : 0),
      transition: ".5s ease",
      position: "absolute",
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      height: "100%",
      width: "100%",
      borderRadius: "12px",
      cursor: "pointer",
      color: theme?.palette?.basic?.white,
      background: theme?.palette?.basic?.black,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    error: {
      marginTop: "7.28px",
      fontSize: "11px",
      color: theme?.palette?.basic?.danger_red,
    },
  }),
  { name: "CreatorTool" }
);
