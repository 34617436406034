import React from "react";

import { Box } from "@material-ui/core";
import { ExlyInput, ExlyRichTextEditor } from "common/form";
import { Field } from "react-final-form";
import ImageInput from "../ImageInput";

import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";
import { useStyles } from "./index.styles";

import { RichTextEditorUtils } from "@my-scoot/component-library-legacy";

const { getHtmlFromEditorState, emptyHtml, emptyHtmlWithListStyle } =
  RichTextEditorUtils;

const CreateBlog = () => {
  const isDesktop = useDesktopMediaQuery();
  const classes = useStyles({ isDesktop });

  return (
    <Box className={classes.root}>
      <Field
        name="thumbnail_image"
        component={ImageInput}
        validate={(value) => !value && "Please upload cover image."}
      />

      <Field
        name="title"
        component={ExlyInput}
        validate={(value) =>
          value
            ? value.length > 100 && "Max 100 characters"
            : "Please enter the blog title."
        }
        label="Blog Title"
        labelClassName={classes.label}
        placeholder="Enter the Title"
        fullWidth
      />

      <Box className={classes.rte}>
        <Box className={classes.label}>Blog Description</Box>
        <Field
          name="content"
          component={ExlyRichTextEditor}
          validate={(value) => {
            const convertedValue = getHtmlFromEditorState(value);

            return (
              (convertedValue === emptyHtml ||
                convertedValue === emptyHtmlWithListStyle) &&
              "Required"
            );
          }}
        />
      </Box>
    </Box>
  );
};

export default CreateBlog;
