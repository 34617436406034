import React, { useState } from "react";

import { Box, Drawer } from "@material-ui/core";
import ImageIcon from "@material-ui/icons/Image";
import CameraAltIcon from "@material-ui/icons/CameraAlt";

import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";
import RightPanel from "ui/widgets/photoUploader/RightPanel";
import { useStyles } from "./index.styles";

const ImageInput = ({ onChange, ...restProps }) => {
  const isDesktop = useDesktopMediaQuery();

  const [hover, setHover] = useState(false);
  const [showRightPanel, setShowRightPanel] = useState(false);

  const classes = useStyles({ isDesktop, hover });

  const { input, meta } = restProps;
  const { onChange: fieldOnChange, name, value } = input;
  const { error, touched } = meta;

  const crop = {
    unit: "%",
    width: isDesktop ? 1024 : 350,
    aspect: isDesktop ? 1024 / 336 : 350 / 115,
  };

  return (
    <Box>
      <Box
        className={classes.root}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        style={
          value
            ? { backgroundImage: `url(${value})`, aspectRatio: "3 / 1" }
            : {}
        }
      >
        {!value && (
          <>
            <ImageIcon className={classes.imgIcon} />
            <Box className={classes.contentContainer}>
              <strong className={classes.title}>No cover image uploaded</strong>
              <div>
                Consider adding a cover image that compliments your article and
                attracts more readers
              </div>
              <div>
                We recommend uploading an image with pixel size: 1080*336
              </div>
            </Box>
          </>
        )}

        <Box
          className={classes.overlay}
          onClick={() => setShowRightPanel(true)}
        >
          <CameraAltIcon />
        </Box>
      </Box>

      {error && touched && <span className={classes.error}>{error}</span>}

      <Drawer
        open={showRightPanel}
        anchor="right"
        variant="temporary"
        onEscapeKeyDown={() => setShowRightPanel(false)}
        onBackdropClick={() => setShowRightPanel(false)}
      >
        <RightPanel
          query="Blogs"
          width={crop.width}
          height={336}
          id="photo"
          crop={crop}
          getUrl={(url) => {
            if (onChange) onChange(url, name);
            fieldOnChange(url);
            setShowRightPanel(false);
          }}
        />
      </Drawer>
    </Box>
  );
};

export default ImageInput;
