import React, { useEffect, useState } from "react";
import { is_empty } from "utils/validations";

import CreateBlog from "./components/CreateBlog";
import PublishBlog from "./components/PublishBlog";
import CreateBlogWizard from "./CreateBlogWizard";

import { RichTextEditorUtils } from "@my-scoot/component-library-legacy";
import { CREATE_MODAL_FORM_KEYS } from "./constants/CreateBlogModal.constants";
import { DEFAULT_TIME_FORMAT_12 } from "constants/date-fns/dateTime.constant";
import { format } from "date-fns";

const { getEditorStateFromHtml, getHtmlFromEditorState } = RichTextEditorUtils;

const customSaveFormats = [
  {
    key: "content",
    preSave: getHtmlFromEditorState,
    postSave: getEditorStateFromHtml,
  },
];

const CreateBlogModal = ({
  initialValues,
  onClose,
  onSubmit,
  ...restProps
}) => {
  const [formState, setFormState] = useState({});

  const { is_draft, is_scheduled, id: blogId } = formState;
  const isPublished = !is_draft && !is_scheduled;
  const isNewBlog = is_empty(blogId);

  useEffect(() => {
    const customInitialValues = { ...initialValues };
    // convert db saved data to UI required data
    customSaveFormats.forEach(({ key, postSave }) => {
      customInitialValues[key] = postSave(customInitialValues[key]);
    });
    setFormState(customInitialValues);
  }, [initialValues]);

  const handleNext = (activeStep, isLastPage, valuesToBeSaved) => {
    setFormState((prev) => {
      const values = { ...prev, ...valuesToBeSaved };

      if (isLastPage) {
        onSubmit(values);
      } else {
        // set schedule time for 2nd page
        values[CREATE_MODAL_FORM_KEYS.scheduled_time] = format(
          new Date(),
          DEFAULT_TIME_FORMAT_12
        );
      }

      return values;
    });
  };

  const getSteps = () => {
    const steps = [
      {
        title: "Create a Blog Post",
        primaryBtnText:
          initialValues?.is_draft === false ? "Update" : "Publish",
        secondaryBtnText: "Save as draft",
        handleSecondaryBtnClick: (form) => {
          let { valid, values } = form.getState();

          if (valid) {
            onSubmit({ ...values, cta: "draft" });
          } else form.submit();
        },
      },
    ];

    if (!isPublished)
      steps.push({
        title: "Ready To Publish Blog",
        primaryBtnText: "Save",
        secondaryBtnText: "Cancel",
      });

    return steps;
  };

  return (
    <CreateBlogWizard
      onNext={handleNext}
      onClose={() => {
        setFormState(initialValues);
        onClose();
      }}
      initialValues={formState}
      steps={getSteps()}
      modalProps={{
        mobile_modal_props: {
          ...(!isNewBlog && { secondaryAction: undefined }),
        },
        modal_props: {
          showSecondaryBtn: isNewBlog,
        },
      }}
      onSubmit={onSubmit}
      isNewBlog={isNewBlog}
      isPublished={isPublished}
      shouldShowPreviewBtn={(currentStep, isLastPage) => !isLastPage}
      {...restProps}
    >
      <CreateBlog onSubmit={onSubmit} {...restProps} />
      <PublishBlog {...restProps} />
    </CreateBlogWizard>
  );
};

export default CreateBlogModal;
