import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  statsRoot: {
    display: "flex",
    gap: "20px",
    alignSelf: "stretch",
    flex: ({ isDesktop }) => !isDesktop && 1,
  },
  stat: {
    display: "flex",
    flexDirection: ({ isDesktop }) => (isDesktop ? "column-reverse" : "column"),
    gap: "8px",
    alignItems: ({ isDesktop }) => !isDesktop && "center",
    color: theme?.palette?.secondary?.main,
    justifyContent: "space-between",
  },
  statValue: {
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "20px",
    letterSpacing: "0.04em",
  },
  verticalDivider: {
    background: "#C4C4C4",
    height: "100%",
    width: "1.5px",
  },
}));
