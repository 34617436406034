import React, { useRef, useState } from "react";
import { Form } from "react-final-form";

import { Button } from "@my-scoot/component-library-legacy";
import ExlyModal from "common/Components/ExlyModal";

import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";
import { useNotifications } from "utils/hooks";
import { VisibilityRounded } from "@material-ui/icons";
import { useStyles } from "./CreateBlogWizard.styles";
import { DesktopFooter } from "./components/DesktopFooter";

const FORM_ID = "create_blog";

const handlePrimaryBtnClick = () => {
  document
    .getElementById(FORM_ID)
    .dispatchEvent(new Event("submit", { cancelable: true }));
};

const CreateBlogWizard = ({
  children,
  open,
  onClose,
  onNext,
  steps,
  modalProps,
  submitting,
  onSubmit,
  isNewBlog,
  editMode,
  ...restProps
}) => {
  const isDesktop = useDesktopMediaQuery();
  const classes = useStyles();
  const { notify } = useNotifications();

  const [activeStep, setActiveStep] = useState(0);

  const formRef = useRef(null);

  const activePage = React.Children.toArray(children)[activeStep];
  const totalSteps = steps.length - 1;
  const isLastPage = activeStep === totalSteps;

  const handleSecondaryBtnClick = () =>
    setActiveStep((prev) => Math.max(0, prev - 1));

  const handleNext = (values) => {
    const valuesToBeSaved = { ...values };

    if (onNext) onNext(activeStep, isLastPage, valuesToBeSaved);

    const nextStep = Math.min(activeStep + 1, totalSteps);

    setActiveStep(nextStep);
  };

  const handlePreview = () => {
    const form = formRef.current;
    let { values: formValues, valid } = form.getState();

    if (valid) {
      onSubmit({ ...formValues, preview: true });
    } else form.submit();
  };

  return (
    <ExlyModal
      open={open}
      onClose={() => {
        setActiveStep(0);
        onClose();
      }}
      title={steps[activeStep].title}
      onPrimaryBtnClick={handlePrimaryBtnClick}
      onSecondaryBtnClick={() =>
        steps[activeStep].handleSecondaryBtnClick?.(formRef.current) ||
        handleSecondaryBtnClick()
      }
      primaryBtnText={steps[activeStep].primaryBtnText}
      secondaryBtnText={steps[activeStep].secondaryBtnText}
      primaryBtnProps={{
        loading: submitting,
        disabled: submitting,
      }}
      {...(isDesktop && {
        customFooter: (
          <DesktopFooter
            primaryBtnText={steps[activeStep].primaryBtnText}
            secondaryBtnText={steps[activeStep].secondaryBtnText}
            onPrimaryBtnClick={handlePrimaryBtnClick}
            onSecondaryBtnClick={() =>
              steps[activeStep].handleSecondaryBtnClick?.(formRef.current) ||
              handleSecondaryBtnClick()
            }
            onPreviewBtnClick={handlePreview}
            showPreviewBtn={(isNewBlog || !editMode) && !isLastPage}
            showSecondaryBtn={isNewBlog}
            primaryBtnProps={{
              loading: submitting,
              disabled: submitting,
            }}
          />
        ),
      })}
      {...modalProps}
    >
      <Form onSubmit={handleNext} {...restProps}>
        {({ form, handleSubmit, invalid }) => {
          formRef.current = form;

          return (
            <>
              <form
                id={FORM_ID}
                className={classes.form}
                onSubmit={(event) => {
                  if (invalid)
                    notify("Please fill the mandatory details.", "error");

                  handleSubmit(event);
                }}
              >
                {activePage}
              </form>

              {(isNewBlog || !editMode) && !isDesktop && !isLastPage && (
                <Button
                  buttonWrapperClassName={classes.buttonWrapperClassName}
                  className={classes.previewBtn}
                  startIcon={<VisibilityRounded />}
                  color="primary"
                  size="small"
                  onClick={handlePreview}
                >
                  Preview
                </Button>
              )}
            </>
          );
        }}
      </Form>
    </ExlyModal>
  );
};

export default CreateBlogWizard;
