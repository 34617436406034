import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(
  (theme) => ({
    desktopModalPaper: {
      maxWidth: "871px",
      width: "100%",
    },
    contanier: {
      padding: "28px 0 30px 0",
      display: "flex",
      flexDirection: "column",
      gap: "23px",
    },
    footer: {
      background: theme.palette.basic.white,
      borderTop: `1.5px solid ${theme.palette.secondary.shade_100}`,
      boxSizing: 'border-box',
      padding: "15.5px 24px 19px 24px",
      display: "flex",
      alignItems: "center",
      gap: "10px",
    },
    newCommentWrapper: {
      flex: 1,
      lineHeight: 0,
    },
    loadingWrapper: {
      display: "flex",
      justifyContent: "center",
      padding: "48px 0px",
      '& img': {
        width: "48px",
      },
    },
    footerLoader: {
      display: "flex",
      justifyContent: "center",
      '& img': {
        width: "24px",
      },
    },
    "@media (max-width:780px)": {
      contanier: {
        overflow: "auto",
        maxHeight: "60vh",
        padding: "27.5px 16px 25.41px 16px",
        gap: "14px",
      },
      footer: {
        padding: "8.5px 16px 12px 16px",
        gap: "8px",
      },
    },
  }),
  { name: "CreatorTool" }
);

export default useStyles;
