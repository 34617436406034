import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      display: "flex",
      flexDirection: "column",
      gap: "24px",
    },
    label: {
      fontSize: "16px",
      fontWeight: ({ isDesktop }) => (isDesktop ? 500 : 700),
      color: theme?.palette?.secondary?.main,
      lineHeight: "20px",
      marginBottom: "8.75px",
    },
    rte: {
      paddingBottom: ({ isDesktop }) => !isDesktop && "80px",
    },
  }),
  { name: "CreatorTool" }
);
