import { validateScheduleTime } from "../utils/CreateBlogModal.utils";

export const CREATE_MODAL_FORM_KEYS = {
  is_scheduled: "is_scheduled",
  scheduled_at: "scheduled_at",
  scheduled_time: "scheduled_time",
};

export const CREATE_MODAL_FORM_VALIDATORS = {
  [CREATE_MODAL_FORM_KEYS.scheduled_time]: validateScheduleTime,
};
