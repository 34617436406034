import constants from "constants/constants";
import { api, dataProvider } from "data";

export const fetchBlogComments = (blogId, pageNumber) =>
  new Promise((resolve, reject) => {
    dataProvider
      .custom_request(
        `${api.comments_reactions.handle_blog_comments}/`,
        "GET",
        {
          post_id: blogId,
          page: pageNumber,
        }
      )
      .then(({ status, data }) => {
        if (status !== 200)
          throw new Error("Failure during fetching blog comments");
        resolve(data);
      })
      .catch(reject);
  });

export const postCommentOnBlog = (blogId, content) =>
  new Promise((resolve, reject) => {
    dataProvider
      .custom_request(
        `${api.comments_reactions.handle_blog_comments}/`,
        "POST",
        {
          post_uuid: blogId,
          content,
        }
      )
      .then(({ status, data }) => {
        if (status !== 200)
          throw new Error("Error while posting a comment on blog");
        resolve(data);
      })
      .catch(reject);
  });

export const updateStatusOfCommentOnBlog = (
  commentId,
  status = constants.commentStatus.INACTIVE
) =>
  new Promise((resolve, reject) => {
    dataProvider
      .custom_request(
        `${api.comments_reactions.handle_blog_comments}/${commentId}/`,
        "PATCH",
        {
          status,
        }
      )
      .then(({ status, data }) => {
        if (status !== 200)
          throw new Error("Failure during updating comment status");
        resolve(data);
      })
      .catch(reject);
  });

export const postReplyOnCommentInBlog = (commentId, content) =>
  new Promise((resolve, reject) => {
    dataProvider
      .custom_request(
        `${api.comments_reactions.handle_comments}/${commentId}/replies/`,
        "POST",
        {
          content,
        }
      )
      .then(({ status, data }) => {
        if (status !== 200)
          throw new Error("Error while posting a reply on comment on blog");
        resolve(data);
      })
      .catch(reject);
  });

export const getRepliesOnCommentInBlog = (commentId) =>
  new Promise((resolve, reject) => {
    dataProvider
      .custom_request(
        `${api.comments_reactions.handle_comments}/${commentId}/replies/`,
        "GET"
      )
      .then(({ status, data }) => {
        if (status !== 200)
          throw new Error("Error while fetching replies on comment on blog");
        resolve(data);
      })
      .catch(reject);
  });
