import React from "react";

import { Box } from "@material-ui/core";
import { Button, Tabs } from "@my-scoot/component-library-legacy";

import BlogCommentsModalComponent from "./components/BlogCommentsModal/BlogCommentsModal.component";
import CreateBlogModal from "./components/CreateBlogContent/CreateBlogModal";
import { ListPagination } from "./components/HelperComponents";
import ListItem from "./components/ListItem/ListItem";
import LoadingState from "./components/LoadingState/LoadingState";

import ExlyDeleteModal from "common/Components/ExlyDeleteModal/ExlyDeleteModal";
import ExlyModal from "common/Components/ExlyModal";
import ExlyTableFilters from "common/Components/ExlyTable/ExlyTableFilters";
import ExlyFilteredEmptyState from "common/Components/ExlyTable/ExlyTableFilters/ExlyTableFilterEmptyState";

import withComponentLibraryTheme from "hocs/withComponentLibraryTheme";
import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";
import { useStyles } from "./index.styles";
import { useBlogs } from "./useBlogs";

import ExlyShareLinkModal from "common/Components/ExlyShareLinkMoal/ExlyShareLinkModal";
import { is_empty } from "../../../../utils/validations";
import {
  BLOGS_LEARN_MORE_HREFS,
  filterChipFormatter,
  filterConfig,
} from "./data";

import EditIcon from "@material-ui/icons/EditRounded";
import ShareIcon from "@material-ui/icons/ShareRounded";
import DeleteIcon from "@material-ui/icons/DeleteRounded";
import ExlyTabsWrapper from "common/Components/ExlyTabsWrapper";
import LearnMoreCta from "features/Common/modules/LearnMore/modules/LearnMoreCta/LearnMoreCta";
import ExlyEmptyState from "features/Common/modules/ExlyEmptyState/ExlyEmptyState";
import { EXLY_EMPTY_STATE_SECONDARY_CTA_VARIANTS } from "features/Common/modules/ExlyEmptyState/constants/ExlyEmptyState.constants";
import useFeatureOnboardingChecker from "common/Components/FeatureOnboarding/useFeatureOnboardingChecker";
import { onboardingFeatureKeys } from "common/Components/FeatureOnboarding/FeatureOnboarding.constants";

const Blogs = (props) => {
  const {
    blogs,
    BLOG_TABS,
    blogFormInitialValues,
    display_name,

    page,
    total,
    loading,
    filterValues,
    setFilters,
    setPage,
    submitting,
    isPublished,

    showEmptyState,
    showNoResultFound,
    setSelectedRecordData,
    selectedRecordData,

    showDeleteModal,
    setShowDeleteModal,

    setShowShare,

    showMoreModal,

    showCreateBlogModal,
    setShowCreateBlogModal,

    editMode,
    setEditMode,

    currentTab,
    isTabChanged,

    formInitialValues,
    setFormInitialValues,

    showCommentsModal,
    selectedBlogUuid,

    handleOpenModal,
    handleCloseModal,
    handleDelete,
    handleEdit,
    handleCreate,
    onOpenCommentsModal,
    onCloseCommentsModal,
    handleTabChange,
    handleShareBlog,
    getEmptyStateProps,
    handleUpdate,
    handleSubmit,

    getShareModalProps,
    hasWriteAccess,
  } = useBlogs(props);
  const {
    isOnboardedPrevVal: isFeatureVisited,
    flagLoading: isFeatureFlagLoading,
    handleFeatureOnboardChange,
  } = useFeatureOnboardingChecker({
    featureKey: onboardingFeatureKeys.KNOWLEDGE_BASE.BLOGS,
    successCallback: (marked) => !marked && handleFeatureOnboardChange(true),
  });

  const isDesktop = useDesktopMediaQuery();

  const classes = useStyles({ isDesktop });

  return (
    <Box className={classes.root}>
      {isDesktop && (
        <Box className={classes.headerRoot}>
          {<Box className={classes.headerTitle}>Create/edit blog post</Box>}
          <Box className={classes.headerRightContainer}>
            {isDesktop && (
              <>
                <LearnMoreCta href={BLOGS_LEARN_MORE_HREFS.BLOGS} />

                <Button
                  color="primary"
                  size="medium"
                  className={classes.createCertificateBtn}
                  onClick={() => handleCreate()}
                  disabled={!hasWriteAccess}
                >
                  Create a Blog Post
                </Button>
              </>
            )}
          </Box>
        </Box>
      )}

      <ExlyTabsWrapper>
        <Tabs
          tabs={Object.values(BLOG_TABS)}
          value={currentTab}
          onChange={(e, value) => {
            handleTabChange(value);
          }}
          tabVariant="outlined"
        />
      </ExlyTabsWrapper>

      {!showEmptyState && (
        <ExlyTableFilters
          className={classes.filterBox}
          filters={filterConfig}
          setFilters={setFilters}
          filterValues={filterValues}
          tableChipsFormatter={filterChipFormatter}
          onClear={() => {
            const { status } = filterValues;
            setFilters({ status });
          }}
          noExportButton
        />
      )}

      {showEmptyState && (
        <ExlyEmptyState
          isLoading={isFeatureFlagLoading}
          title="No Feedback Received Yet."
          description="As soon as we have a new feedback, it will show up here. View the video to learn more."
          playerProps={{
            playing:
              !isFeatureFlagLoading && !isFeatureVisited && !isTabChanged, // Autoplay the knowledge base video on the first visit to this feature
          }}
          secondaryCtaProps={{
            variant: EXLY_EMPTY_STATE_SECONDARY_CTA_VARIANTS.learn_more,
            learnMoreHref: BLOGS_LEARN_MORE_HREFS.BLOGS,
          }}
          {...getEmptyStateProps(currentTab)}
        />
      )}

      {showNoResultFound && <ExlyFilteredEmptyState />}

      <Box className={classes.listContainer}>
        {loading && <LoadingState />}

        {!is_empty(blogs) &&
          !loading &&
          blogs.map((blog, index) => (
            <ListItem
              key={blog.id}
              isDesktop={isDesktop}
              hasWriteAccess={hasWriteAccess}
              onEdit={() => {
                handleEdit(blog);
              }}
              onShare={(e) => {
                e.stopPropagation();
                setSelectedRecordData(blog);
                setShowShare(true);
              }}
              onDelete={() => {
                setSelectedRecordData(blog);
                setShowDeleteModal(blog);
              }}
              onMoreClick={() => {
                setSelectedRecordData(blog);
                handleOpenModal();
              }}
              onClickComment={(e) => onOpenCommentsModal(e, blog)}
              {...{ ...blog, index, display_name }}
            />
          ))}
      </Box>

      <ListPagination total={total} page={page} onPageChange={setPage} />

      {!isDesktop && (
        <Button
          buttonWrapperClassName={classes.footerCta}
          onClick={() => handleCreate()}
          color="primary"
          size="small"
          disabled={!hasWriteAccess}
          style={{
            height: "34px",
            width: "calc(100vw - 32px)",
          }}
        >
          Create a Blog Post
        </Button>
      )}

      <ExlyShareLinkModal {...getShareModalProps()} />

      <ExlyDeleteModal
        open={showDeleteModal}
        onClose={() => {
          setShowDeleteModal(false);
        }}
        onDelete={handleDelete}
        title="Confirm Delete"
        description="Are you sure you want to delete the blog?"
      />

      {showCommentsModal && selectedBlogUuid && (
        <BlogCommentsModalComponent
          open={showCommentsModal}
          onClose={onCloseCommentsModal}
          blog_uuid={selectedBlogUuid}
        />
      )}

      <ExlyModal
        open={showMoreModal}
        onClose={handleCloseModal}
        mobile_modal_props={{
          customFooter: <></>,
          customHeader: <></>,
        }}
        backDropClickClose
      >
        <Box className={classes.moreOptions}>
          {hasWriteAccess && (
            <div
              onClick={() => {
                handleEdit(selectedRecordData);
                handleCloseModal();
              }}
            >
              <EditIcon className="mr-1" /> Edit
            </div>
          )}
          {isPublished && (
            <div onClick={handleShareBlog}>
              <ShareIcon className="mr-1" /> Share
            </div>
          )}
          {hasWriteAccess && (
            <div
              onClick={() => {
                setShowDeleteModal(selectedRecordData);
                handleCloseModal();
              }}
            >
              <DeleteIcon className="mr-1" /> Delete
            </div>
          )}
        </Box>
      </ExlyModal>

      {showCreateBlogModal && (
        <CreateBlogModal
          open={showCreateBlogModal}
          onClose={() => {
            setFormInitialValues(blogFormInitialValues);
            setShowCreateBlogModal(false);
            setEditMode(false);
          }}
          submitting={submitting}
          initialValues={formInitialValues}
          editMode={editMode}
          onSubmit={(values) => {
            const { cta, preview, ...blogData } = values;
            const { id: blogId } = blogData;
            const isNewBlog = is_empty(blogId);

            const reciepientsData = blogData?.recipients?.map(
              (item) => item.value
            );
            blogData.recipients = [...reciepientsData];

            return isNewBlog
              ? handleSubmit(
                  blogData,
                  cta === "draft" || preview ? true : false,
                  preview
                )
              : handleUpdate(blogData, preview);
          }}
        />
      )}
    </Box>
  );
};

export default withComponentLibraryTheme(Blogs);
