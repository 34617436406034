import React, { useState, useCallback, useMemo, useEffect } from "react";
import { Button, Avatar, Input } from "@my-scoot/component-library-legacy";

import useStyles from "./BlogCommentsModal.styles";
import withComponentLibraryTheme from "hocs/withComponentLibraryTheme";
import { ReactComponent as PlayArrowIcon } from "assets/vectors/playArrow.svg";
import BookingLoader from "assets/images/bookingLoader.gif";

import ExlyModal from "common/Components/ExlyModal";
import Comment from "common/Components/Comment";
import EmptyComments from "common/Components/Comment/components/EmptyComments";

import {
  getRepliesOnCommentInBlog,
  postCommentOnBlog,
  postReplyOnCommentInBlog,
  updateStatusOfCommentOnBlog,
  fetchBlogComments,
} from "../../Blogs.helpers";
import constants from "constants/constants";
import IconButton from "@material-ui/core/IconButton";
import { is_empty } from "utils/validations";
import useInfiniteSearch from "hooks/useInfiniteSearch";
import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";
import { getAuthData } from "utils/AuthUtil";

const BlogCommentsModal = ({ blog_uuid, onClose }) => {
  const {
    data: comments,
    setLoading,
    setError,
    pageNumber,
    setHasMore,
    loading,
    setData: setComments,
    lastElementRef,
    setPageNumber,
  } = useInfiniteSearch();

  const is_desktop = useDesktopMediaQuery();
  const [newCommentText, setNewCommentText] = useState("");
  const [postingComment, setPostingComment] = useState(false);
  const classes = useStyles({ is_desktop });

  const loggedInUserName = useMemo(() => {
    const authJson = getAuthData();
    const authValue = authJson ? authJson : {};
    return authValue.display_name;
  }, []);

  const fetchComments = (pageNumber, initialData) => {
    if (!blog_uuid) return;
    setLoading(true);
    setError(false);

    fetchBlogComments(blog_uuid, pageNumber)
      .then((data) => {
        setComments((prevData) => {
          const prevComments = initialData || prevData;
          const results = data?.results || [];
          return [...new Set([...prevComments, ...results])];
        });
        setHasMore(data?.total_pages > pageNumber);
        setLoading(false);
      })
      .catch(() => {
        setError(true);
      });
  };

  useEffect(() => {
    fetchComments(pageNumber);
  }, [pageNumber]);

  const updateCommentStatus = useCallback(async (commentId, status) => {
    try {
      await updateStatusOfCommentOnBlog(commentId, status);
      setPageNumber(1);
      fetchComments(1, []);
    } catch (err) {
      console.log(`Error while updating comment status `, err);
    }
  }, []);

  const getCommentActions = useCallback(
    (comment) => {
      const isHidden = comment.status === constants.commentStatus.HIDDEN;
      return [
        {
          label: isHidden ? "Unhide" : "Hide",
          onClick: () =>
            updateCommentStatus(
              comment.id,
              isHidden
                ? constants.commentStatus.ACTIVE
                : constants.commentStatus.HIDDEN
            ),
        },
        {
          label: "Delete",
          onClick: () =>
            updateCommentStatus(comment.id, constants.commentStatus.INACTIVE),
        },
      ];
    },
    [updateCommentStatus]
  );

  const onPostComment = async () => {
    if (!newCommentText) return;
    setPostingComment(true);
    postCommentOnBlog(blog_uuid, newCommentText)
      .then(() => {
        setNewCommentText("");
        setPageNumber(1);
        fetchComments(1, []);
      })
      .catch((err) => {
        console.log(`Error while posting comment on blog`, err);
      })
      .finally(() => setPostingComment(false));
  };

  const footer = (
    <div className={classes.footer}>
      <Avatar colorIndex={0} label={loggedInUserName} />
      <Input
        placeholder="Write Your comment here"
        value={newCommentText}
        onChange={(e) => setNewCommentText(e.target.value)}
        wrapperClassName={classes.newCommentWrapper}
        fullWidth
      />
      {postingComment ? (
        <div className={classes.footerLoader}>
          <img src={BookingLoader} alt="loading..." />
        </div>
      ) : is_desktop ? (
        <Button size="small" onClick={onPostComment} disabled={!newCommentText}>
          Post Comment
        </Button>
      ) : (
        <IconButton onClick={onPostComment} disabled={!newCommentText}>
          <PlayArrowIcon />
        </IconButton>
      )}
    </div>
  );

  return (
    <ExlyModal
      title="Comments"
      open={blog_uuid}
      onClose={onClose}
      modal_props={{
        customFooter: footer,
        modalPaperClassName: classes.desktopModalPaper,
      }}
      mobile_modal_props={{
        customFooter: footer,
      }}
      padded={is_desktop}
    >
      <div className={classes.contanier}>
        {is_empty(comments) && !loading && <EmptyComments />}

        {comments.map((comment, index) => {
          if (comments.length === index + 1) {
            return (
              <div ref={lastElementRef} key={comment}>
                <Comment
                  comment={comment}
                  getCommentActions={getCommentActions}
                  loggedInUserName={loggedInUserName}
                  postReply={postReplyOnCommentInBlog}
                  getReplies={getRepliesOnCommentInBlog}
                  deleteReply={updateStatusOfCommentOnBlog}
                />
              </div>
            );
          } else {
            return (
              <div key={comment}>
                <Comment
                  comment={comment}
                  getCommentActions={getCommentActions}
                  loggedInUserName={loggedInUserName}
                  postReply={postReplyOnCommentInBlog}
                  getReplies={getRepliesOnCommentInBlog}
                  deleteReply={updateStatusOfCommentOnBlog}
                />
              </div>
            );
          }
        })}
        {loading && (
          <div className={classes.loadingWrapper}>
            <img src={BookingLoader} alt="loading..." />
          </div>
        )}
      </div>
    </ExlyModal>
  );
};

export default withComponentLibraryTheme(BlogCommentsModal);
