import React from "react";

import { getUserTimezone } from "utils/AuthUtil";
import { useStyles } from "./Lisitem.styles";

import { Box, IconButton } from "@material-ui/core";
import {
  ChatBubble as CommentIcon,
  DeleteRounded as DeleteIcon,
  EditRounded as EditIcon,
  EmailRounded as EmailIcon,
  Favorite as LikeIcon,
  MoreHoriz as MoreIcon,
  ShareRounded as ShareIcon,
  Visibility as ViewsIcon,
} from "@material-ui/icons";
import { Tooltip } from "@my-scoot/component-library-legacy";
import StatsField from "./components/StatsField/StatsField";

import UnknownIcon from "assets/images/unkown_user.jpg";

import classnames from "classnames";
import moment from "moment";
import constants from "constants/constants";

const ListItem = ({ isDesktop, ...restProps }) => {
  const classes = useStyles({ isDesktop });

  const {
    thumbnail_image,
    title,
    display_name,
    created_at,
    scheduled_at,
    is_draft,
    is_scheduled,
    like_count,
    open_rate_count,
    comment_count,
    views_count,
    email_recipients: email_recipients_count,
    email_open_rate,
    onEdit,
    onShare,
    onDelete,
    onMoreClick,
    onClickComment,
    index,
    hasWriteAccess,
  } = restProps;

  const isScheduled = is_draft && is_scheduled;
  const isDraft = is_draft && !is_scheduled;
  const isPublished = !isScheduled && !isDraft;
  const isPublishedAfterScheduled = !is_draft && is_scheduled;
  const isAlternate = index % 2;

  const stats = [
    {
      label: <LikeIcon className={classes.likeLabel} />,
      value: like_count,
      showDivider: isDesktop,
      valueClassName: classes.likeValue,
    },
    {
      label: (
        <CommentIcon
          className={classes.commentLabel}
          onClick={hasWriteAccess ? onClickComment : () => {}}
        />
      ),
      value: comment_count,
      showDivider: isDesktop,
    },
    {
      label: <ViewsIcon className={classes.viewsLabel} />,
      value: views_count,
      showDivider: isDesktop,
      valueClassName: classes.viewsValue,
    },
    {
      label: isDesktop ? (
        "Email recipient"
      ) : (
        <Tooltip title="Email recipient" placement="top" color="primary" arrow>
          <EmailIcon className={classes.recipientsLabel} />
        </Tooltip>
      ),
      value: email_recipients_count,
      isReverse: true,
      showDivider: isDesktop,
      valueClassName: classes.recipientsValue,
    },
    {
      label: isDesktop ? (
        "Email open rate(%)"
      ) : (
        <EmailIcon className={classes.recipientsLabel} />
      ),
      value: isDesktop
        ? `${open_rate_count}(${email_open_rate}%)`
        : `${email_open_rate}%`,
      isReverse: true,
      showDivider: false,
      valueClassName: classes.recipientsValue,
    },
  ];

  return (
    <Box className={classnames(classes.root, isAlternate && classes.alternate)}>
      <img src={thumbnail_image || UnknownIcon} className={classes.img} />

      <Box className={classes.contentContainer}>
        <Box className={classes.titleContainer}>
          <span className={classes.title} onClick={onEdit}>
            {title}
          </span>

          <Box className={classes.actionsContainer}>
            {isDesktop && (
              <>
                {hasWriteAccess && (
                  <Tooltip
                    title="Edit Blog"
                    placement="top"
                    color="primary"
                    arrow
                  >
                    <IconButton className={classes.actionBtn} onClick={onEdit}>
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                )}

                {isPublished && (
                  <Tooltip
                    title="Share Blog"
                    placement="top"
                    color="primary"
                    arrow
                  >
                    <IconButton className={classes.actionBtn} onClick={onShare}>
                      <ShareIcon />
                    </IconButton>
                  </Tooltip>
                )}

                {hasWriteAccess && (
                  <Tooltip
                    title="Delete Blog"
                    placement="top"
                    color="primary"
                    arrow
                  >
                    <IconButton
                      className={classes.actionBtn}
                      onClick={onDelete}
                      disabled={!hasWriteAccess}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                )}
              </>
            )}
            {!isDesktop && (hasWriteAccess || isPublished) && (
              <IconButton
                className={classnames(classes.actionBtn, classes.moreIcon)}
                onClick={onMoreClick}
              >
                <MoreIcon />
              </IconButton>
            )}
          </Box>
        </Box>

        <Box className={classes.name}>{display_name}</Box>

        <Box className={classes.createdAt}>
          {isPublished && "Published "}
          {isScheduled && (
            <span className={classnames(isScheduled && classes.scheduled)}>
              Scheduled for{" "}
            </span>
          )}
          {isDraft && "Edited "}
          {moment(
            isScheduled || isPublishedAfterScheduled ? scheduled_at : created_at
          )
            .tz(getUserTimezone())
            .format(constants.blogsMomentDateTimeFormat)}
          {(isScheduled || isDraft) && " by you"}
        </Box>

        {!isPublished && (
          <Box className={classes.statusContainer}>
            {isDraft && (
              <span className={classnames(classes.badge, classes.draftBadge)}>
                Draft
              </span>
            )}

            {isScheduled && (
              <span
                className={classnames(classes.badge, classes.scheduledBadge)}
              >
                Scheduled
              </span>
            )}
          </Box>
        )}

        {isPublished && (
          <>
            <div className={classes.divider} />
            <Box className={classes.statsContainer}>
              {stats.map((stat, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <StatsField key={index} {...stat} />
              ))}
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};

export default ListItem;
