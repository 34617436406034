import React from "react";

import { Box } from "@material-ui/core";
import { Pagination } from "common/Components/Pagination";

export const ListPagination = (props) => {
  return (
    <Box style={{ marginLeft: "auto" }}>
      <Pagination {...props} />
    </Box>
  );
};
