import React from "react";
import { useStyles } from "./StatsField.styles";
import classnames from "classnames";
import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";
import { Box } from "@material-ui/core";

export const StatsField = ({
  value,
  label,
  valueClassName,
  labelClassName,
  showDivider = true,
  ...restProps
}) => {
  const isDesktop = useDesktopMediaQuery();
  const classes = useStyles({ isDesktop });

  return (
    <Box className={classes.statsRoot} {...restProps}>
      <Box className={classes.stat}>
        <Box className={labelClassName}>{label}</Box>
        <Box className={classnames(classes.statValue, valueClassName)}>
          {value}
        </Box>
      </Box>
      {showDivider && <div className={classes.verticalDivider} />}
    </Box>
  );
};

export default StatsField;
