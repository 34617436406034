import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      display: "flex",
      flexDirection: "column",
      gap: "20px",
      overflowY: "scroll",
      padding: ({ isDesktop }) =>
        isDesktop ? "20px 24px" : "16px 16px 68px 16px",
      "&::-webkit-scrollbar": {
        display: "none",
      },
    },
    headerTitle: {
      color: theme?.palette?.secondary?.main,
      fontWeight: 700,
      fontSize: "18px",
      lineHeight: "21.98px",
    },
    headerRoot: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    headerRightContainer: {
      display: "flex",
      alignItems: "center",
      gap: "24px",
    },
    learnMore: {
      color: theme?.palette?.primary?.main,
      fontSize: "16px",
      fontWeight: 500,
      textDecoration: "underline",
      cursor: "pointer",
      whiteSpace: "nowrap",
    },
    listContainer: {
      width: ({ isDesktop }) => !isDesktop && "calc(100vw - 32px)",
      display: "flex",
      flexDirection: "column",
      gap: "20px",
    },
    footerCta: {
      borderRadius: "0px",
      borderTop: `1.5px solid ${theme?.palette?.secondary.shade_100}`,
      padding: "8px 14px",
      position: "fixed",
      bottom: 0,
      left: 0,
      width: "100%",
      height: "52px",
      background: theme?.palette?.basic?.white,
    },
    paginationWrapper: {
      marginLeft: "auto",
    },
    moreOptions: {
      padding: "16px 0",
      display: "flex",
      flexDirection: "column",
      gap: "20px",
      fontSize: "16px",
      color: theme?.palette?.secondary?.main,
    },
    filterBox: {
      alignSelf: ({ isDesktop }) => isDesktop && "flex-start",
      marginBottom: 0,
    },
  }),
  { name: "CreatorTool" }
);
