/* eslint-disable react/no-array-index-key */
import React from "react";

import { Box } from "@material-ui/core";
import { Shimmer } from "@my-scoot/component-library-legacy";

import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";
import { useStyles } from "./LoadingState.styles";

import classnames from "classnames";

const STATS_SHIMMER_ITEMS_SIZE = 5;

const statsShimmerArray = [...Array(STATS_SHIMMER_ITEMS_SIZE).keys()];

const ShimmerItem = ({ index, isDesktop, classes }) => {
  const isAlternate = index % 2;

  return (
    <Box className={classnames(classes.root, isAlternate && classes.alternate)}>
      <Shimmer wrapper_classname={classes.img} />

      <Box className={classes.contentContainer}>
        <Box className={classes.titleContainer}>
          <Shimmer
            wrapper_classname={classes.title}
            height="20px"
            width="45px"
            borderRadius="8px"
          />

          <Box className={classes.actionsContainer}>
            {isDesktop ? (
              <>
                <Shimmer
                  wrapper_classname={classes.actionBtn}
                  height="24px"
                  width="24px"
                  borderRadius="50%"
                />
                <Shimmer
                  wrapper_classname={classes.actionBtn}
                  height="24px"
                  width="24px"
                  borderRadius="50%"
                />
                <Shimmer
                  wrapper_classname={classes.actionBtn}
                  height="24px"
                  width="24px"
                  borderRadius="50%"
                />
              </>
            ) : (
              <Shimmer
                wrapper_classname={classes.actionBtn}
                height="24px"
                width="24px"
                borderRadius="50%"
              />
            )}
          </Box>
        </Box>

        <Shimmer
          wrapper_classname={classes.name}
          height="19px"
          width="117px"
          borderRadius="8px"
        />

        <Shimmer
          wrapper_classname={classes.createdAt}
          height="16px"
          width="155px"
          borderRadius="8px"
        />

        <Shimmer
          wrapper_classname={classes.divider}
          height="2px"
          width="100%"
        />

        <Box className={classes.statsContainer}>
          {statsShimmerArray.map((_, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Box key={index} className={classes.statsRoot}>
              <Box className={classes.stat}>
                <Shimmer height="19px" width="19px" borderRadius="50%" />
                <Shimmer height="19px" width="19px" borderRadius="50%" />
              </Box>
              {index < STATS_SHIMMER_ITEMS_SIZE - 1 && isDesktop && (
                <Shimmer
                  wrapper_classname={classes.verticalDivider}
                  width="1.5px"
                />
              )}
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

const SHIMMER_ITEMS_SIZE = 10;

const shimmerArray = [...Array(SHIMMER_ITEMS_SIZE).keys()];

const LoadingState = () => {
  const isDesktop = useDesktopMediaQuery();
  const classes = useStyles({ isDesktop });

  return shimmerArray.map((_, index) => (
    <ShimmerItem
      // eslint-disable-next-line react/no-array-index-key
      key={index}
      index={index}
      classes={classes}
      isDesktop={isDesktop}
    />
  ));
};

export default LoadingState;
