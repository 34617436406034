import { makeStyles } from "@material-ui/core";
import { is_desktop } from "utils/Utils";

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      display: "flex",
      minHeight: "197.39px",
    },
    contentContainer: {
      width: "100%",
      padding: "16px",
      border: `1.5px solid ${theme?.palette?.secondary?.shade_100}`,
      borderRadius: "0 8px 8px 0",
      borderLeft: "none",
      display: "flex",
      flexDirection: "column",
      gap: "12px",
    },
    img: {
      minWidth: is_desktop ? "223px" : "117px",
      maxWidth: is_desktop ? "223px" : "117px",
      borderRadius: "8px 0 0 8px",
      objectFit: "cover",
      background: theme?.palette?.secondary?.shade_100,
    },
    titleContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    actionsContainer: {
      display: "flex",
      alignItems: "center",
      gap: "18px",
      "& button": {
        padding: 0,
      },
      "& svg": {
        color: theme?.palette?.secondary?.shade_600,
      },
    },
    title: {
      fontSize: "16px",
      fontWeight: 700,
      color: theme?.palette?.secondary?.main,
      lineHeight: "20px",
    },
    name: {
      color: theme?.palette?.secondary?.main,
      fontSize: "16px",
      lineHeight: "19px",
    },
    createdAt: {
      color: theme?.palette?.secondary?.main,
      fontSize: "14px",
      lineHeight: "17px",
      letterSpacing: "0.04em",
    },

    badge: {
      borderRadius: "11.5px",
      fontSize: "12px",
      lineHeight: "14px",
      padding: "4.5px 6px",
    },
    draftBadge: {
      color: theme?.palette?.coral_red?.shade_600,
      background: theme?.palette?.coral_red?.shade_100,
    },
    scheduledBadge: {
      color: theme?.palette?.butterscotch_yellow?.shade_600,
      background: theme?.palette?.butterscotch_yellow?.shade_100,
    },
    statusContainer: {
      flex: 1,
      display: "flex",
      alignItems: "center",
    },
    statsContainer: {
      display: "flex",
      gap: "20px",
      alignItems: "flex-start",
    },
    divider: {
      borderTop: `2px solid ${theme?.palette?.secondary?.shade_100}`,
      marginRight: "-16px",
    },

    likeLabel: {
      fontSize: "19px",
      color: theme?.palette?.coral_red?.main,
    },
    likeValue: {
      color: theme?.palette?.coral_red?.main,
    },
    commentLabel: {
      fontSize: "17.45px",
    },
    viewsLabel: {
      fontSize: "23.25px",
      color: theme?.palette?.primary?.main,
    },
    viewsValue: {
      color: theme?.palette?.primary?.main,
    },
    recipientsValue: {
      color: theme?.palette?.persian_blue?.main,
    },
    recipientsLabel: {
      fontSize: "21.8px",
      color: theme?.palette?.persian_blue?.main,
    },

    alternate: {
      background: theme?.palette?.secondary?.shade_50,
      borderRadius: "8px",
      overflow: "hidden",
    },
    moreIcon: {
      "& svg": {
        color: theme?.palette?.primary?.main,
      },
    },
    moreOptions: {
      padding: "16px",
      display: "flex",
      flexDirection: "column",
      gap: "20px",
      fontSize: "16px",
      color: theme?.palette?.secondary?.main,
    },
    statsRoot: {
      display: "flex",
      gap: "20px",
      alignSelf: "stretch",
      flex: ({ isDesktop }) => !isDesktop && 1,
    },
    stat: {
      display: "flex",
      flexDirection: ({ isDesktop }) =>
        isDesktop ? "column-reverse" : "column",
      gap: "8px",
      alignItems: ({ isDesktop }) => !isDesktop && "center",
      color: theme?.palette?.secondary?.main,
      justifyContent: "space-between",
    },
    statValue: {
      fontSize: "16px",
      fontWeight: 700,
      lineHeight: "20px",
      letterSpacing: "0.04em",
    },
    verticalDivider: {
      background: "#C4C4C4",
      height: "100%",
      width: "1.5px",
    },
  }),
  { name: "CreatorTool" }
);
