import React from "react";
import classnames from "classnames";
import { Button } from "@my-scoot/component-library-legacy";
import { Box } from "@material-ui/core";
import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";
import { VisibilityRounded } from "@material-ui/icons";
import { useStyles } from "../CreateBlogWizard.styles";

export const DesktopFooter = ({
  primaryBtnText,
  secondaryBtnText,
  onPrimaryBtnClick,
  onSecondaryBtnClick,
  onPreviewBtnClick,
  showPreviewBtn,
  showSecondaryBtn,
  primaryBtnProps,
}) => {
  const isDesktop = useDesktopMediaQuery();
  const classes = useStyles({ isDesktop });

  return (
    <Box className={classes.dialogFooter}>
      {showSecondaryBtn && (
        <Button
          className={classnames(classes.footerBtn, classes.secondaryBtn)}
          color="subtleSecondary"
          variant="text"
          size="medium"
          onClick={onSecondaryBtnClick}
        >
          {secondaryBtnText}
        </Button>
      )}

      {showPreviewBtn && (
        <Button
          className={classes.footerBtn}
          startIcon={<VisibilityRounded />}
          color="secondary"
          size="medium"
          variant="outlined"
          onClick={onPreviewBtnClick}
        >
          Preview
        </Button>
      )}

      <Button
        className={classes.footerBtn}
        size="medium"
        color="primary"
        onClick={onPrimaryBtnClick}
        {...primaryBtnProps}
      >
        {primaryBtnText}
      </Button>
    </Box>
  );
};
