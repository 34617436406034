import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(
  (theme) => ({
    checkboxContainer: {
      display: "flex",
      gap: "20px",
    },
    checkboxLabel: {
      fontSize: "18px",
      fontWeight: 500,
      lineHeight: "22px",
      color: theme?.palette?.secondary?.main,
    },
    checkboxDescription: {
      fontSize: "14px",
      lineHeight: "17px",
      color: theme?.palette?.secondary.main,
    },
    publishModalRoot: {
      display: "flex",
      flexDirection: "column",
      gap: "20px",
    },
    checkbox: {
      marginTop: "3px",
    },
    checkboxRightContainer: {
      display: "flex",
      justifyContent: "space-between",
      gap: "8px",
      alignItems: ({ isDesktop }) => !isDesktop && "stretch",
      flexDirection: ({ isDesktop }) => (isDesktop ? "row" : "column"),
      flex: ({ isDesktop }) => !isDesktop && 1,
    },
    checboxContentContainer: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      gap: "12px",
    },
    duration: {
      display: "flex",
      flexDirection: "column",
      gap: "12px",
    },
  }),
  { name: "CreatorTool" }
);
