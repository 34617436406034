import { Box } from "@material-ui/core";
import { Card, Checkbox } from "@my-scoot/component-library-legacy";
import { ExlyDatePicker, ExlyTimePicker } from "common/form";
import ExlyEmailRecipientInput from "common/form/ExlyEmailRecipientInput";
import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";
import React from "react";
import { Field, useForm, useFormState } from "react-final-form";
import { useStyles } from "./index.styles";
import {
  CREATE_MODAL_FORM_KEYS,
  CREATE_MODAL_FORM_VALIDATORS,
} from "../../constants/CreateBlogModal.constants";

const PublishBlog = () => {
  const isDesktop = useDesktopMediaQuery();
  const classes = useStyles({ isDesktop });

  const form = useForm();
  const { values: formValues } = useFormState();

  return (
    <Box className={classes.publishModalRoot}>
      <Card
        variant="outlined"
        shadow="shadow_sm"
        style={{ padding: "16px" }}
        onClick={() => form.change("is_scheduled", false)}
      >
        <Box className={classes.checkboxContainer}>
          <Checkbox
            size="large"
            wrapperClassName={classes.checkbox}
            checked={!formValues.is_scheduled}
          />
          <Box className={classes.checboxContentContainer}>
            <Box className={classes.checkboxLabel}>Set it live now</Box>
            <Box className={classes.checkboxDescription}>
              Publish this post immediately on website
            </Box>
          </Box>
        </Box>
      </Card>

      <Card variant="outlined" shadow="shadow_sm" style={{ padding: "16px" }}>
        <Box className={classes.checkboxContainer}>
          <Checkbox
            size="large"
            wrapperClassName={classes.checkbox}
            checked={formValues.showRecipients}
            onClick={() =>
              form.change(
                "showRecipients",
                formValues.showRecipients ? false : true
              )
            }
          />
          <Box className={classes.checboxContentContainer}>
            <Box className={classes.checkboxLabel}>Send by email to</Box>
            <Box className={classes.checkboxDescription}>
              Send new post as email newsletter
            </Box>
            {formValues.showRecipients && (
              <Field
                name="recipients"
                component={ExlyEmailRecipientInput}
                helperText="Type email or click on group names to add recipients. You can add multiple recipients."
              />
            )}
          </Box>
        </Box>
      </Card>

      <Card
        variant="outlined"
        shadow="shadow_sm"
        style={{ padding: "16px" }}
        onClick={() => form.change("is_scheduled", true)}
      >
        <Box className={classes.checkboxContainer}>
          <Checkbox
            size="large"
            wrapperClassName={classes.checkbox}
            checked={formValues.is_scheduled}
          />
          <Box className={classes.checkboxRightContainer}>
            <Box className={classes.checboxContentContainer}>
              <Box className={classes.checkboxLabel}>Schedule it for later</Box>
              <Box className={classes.checkboxDescription}>
                Publish the post in the scheduled time
              </Box>
            </Box>

            <Box className={classes.duration}>
              <Field
                name={CREATE_MODAL_FORM_KEYS.scheduled_at}
                component={ExlyDatePicker}
                fullWidth
                minDate={new Date()}
                popperPlacement="top-right"
                disabled={!formValues.is_scheduled}
              />

              <Field
                name={CREATE_MODAL_FORM_KEYS.scheduled_time}
                component={ExlyTimePicker}
                fullWidth
                uniqueId="blog_scheduled_time"
                popperWidth="257.59px"
                validate={
                  CREATE_MODAL_FORM_VALIDATORS[
                    CREATE_MODAL_FORM_KEYS.scheduled_time
                  ]
                }
              />
            </Box>
          </Box>
        </Box>
      </Card>
    </Box>
  );
};

export default PublishBlog;
