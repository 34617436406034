import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(
  (theme) => ({
    form: {
      padding: "24px 0",
    },
    dialogFooter: {
      height: "80px",
      boxSizing: "border-box",
      borderTop: `1.5px solid ${theme?.palette?.secondary?.shade_100}`,
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      gap: "16px",
      padding: theme.spacing(0, 6),
    },
    footerBtn: {
      padding: "0 16px",
    },
    secondaryBtn: {
      color: theme?.palette?.secondary?.shade_700,
    },
    buttonWrapperClassName: {
      position: "fixed",
      bottom: "96px",
      right: "16px",
      zIndex: 9999,
    },
    previewBtn: {
      borderRadius: "20px",
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.16)",
    },
  }),
  { name: "CreatorTool" }
);
